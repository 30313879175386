<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        title="Add New"
        v-else
      >
        <v-icon>{{icon.add}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <!-- employee -->
        <v-autocomplete
          v-if="outletAssignments.length > 1"
          v-model="outlet"
          outlined
          dense
          clearable
          label="Outlet"
          item-value="id"
          item-text="title"
          :loading="outletLoading"
          :items="outlets"
          :class="formErrorFields.outletErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.outletErrorMessage"
          :disabled="formState===null"
          @change="getEmployee"
        ></v-autocomplete>

        <v-autocomplete
          v-model="employee"
          outlined
          dense
          clearable
          label="Employee"
          item-value="id"
          item-text="name"
          :loading="employeeLoading"
          :items="employees"
          :class="formErrorFields.employeeErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.employeeErrorMessage"
          :disabled="formState===null"
          @change="formError().remove('employee', formErrorFields)"
        ></v-autocomplete>

        <!-- Date -->
        <v-dialog
          ref="dialog"
          v-model="modalApplyTo"
          :return-value.sync="applyToValue"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="applyTo"
              label="Apply To"
              outlined
              dense
              placeholder="Apply To"
              v-bind="attrs"
              v-on="on"
              :append-icon="icon.calendar"
              :class="formErrorFields.applyToErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.applyToErrorMessage"
              :disabled="formState===null"
              @click="formError().remove('applyTo', formErrorFields)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="applyTo" scrollable>
            <v-btn text color="primary" @click="modalApplyTo=false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalApplyTo=false">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Start Date -->
            <v-dialog
              ref="dialog"
              v-model="modalStartDate"
              :return-value.sync="startDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Start Date"
                  outlined
                  dense
                  placeholder="Start Date"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.calendar"
                  :class="formErrorFields.startDateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.startDateErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('startDate', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate" scrollable>
                <v-btn text color="primary" @click="modalStartDate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalStartDate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Start Time -->
            <v-dialog
              ref="dialog"
              v-model="modalTimeStart"
              :return-value.sync="timeStartValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeStart"
                  label="Time Start"
                  outlined
                  dense
                  placeholder="Time Start"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.clock"
                  :class="formErrorFields.timeStartErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.timeStartErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('timeStart', formErrorFields)"
                ></v-text-field>
              </template>
              <v-time-picker v-model="timeStart" scrollable>
                <v-btn text color="primary" @click="modalTimeStart=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalTimeStart=false">
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- End Date -->
            <v-dialog
              ref="dialog"
              v-model="modalEndDate"
              :return-value.sync="endDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="End Date"
                  outlined
                  dense
                  placeholder="End Date"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.calendar"
                  :class="formErrorFields.endDateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.endDateErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('endDate', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="endDate" scrollable>
                <v-btn text color="primary" @click="modalEndDate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalEndDate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Time End -->
            <v-dialog
              ref="dialog"
              v-model="modalTimeEnd"
              :return-value.sync="timeEndValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeEnd"
                  label="Time End"
                  outlined
                  dense
                  placeholder="Time End"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.clock"
                  :class="formErrorFields.timeEndErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.timeEndErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('timeEnd', formErrorFields)"
                ></v-text-field>
              </template>
              <v-time-picker v-model="timeEnd" scrollable>
                <v-btn text color="primary" @click="modalTimeEnd=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalTimeEnd=false">
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-text-field
          v-model="breakTime"
          label="Break time (hrs)"
          outlined
          dense
          type="number"
          placeholder="Break time (hrs)"
          :class="formErrorFields.breakTimeErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.breakTimeErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('breakTime', formErrorFields)"
        ></v-text-field>

        <v-textarea
          v-model="remarks"
          outlined
          dense
          clearable
          label="Remarks"
          class="mb-3"
          rows="3"
          :error-messages="formErrorFields.remarksErrorMessage"
          :disabled="formState===null"
          @keydown="formError().remove('remarks', formErrorFields)"
        ></v-textarea>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCalendarOutline, mdiClockOutline, mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import fnx from '@/functions/fn'

export default {
  props: ['data'],
  setup(props, { emit, root }) {
    const openUploader = ref(false)
    const loading = ref(false)
    const message = ref('')
    const icon = {
      add: mdiPlusOutline,
      calendar: mdiCalendarOutline,
      clock: mdiClockOutline,
    }
    const employee = ref(null)
    const employees = ref([])
    const employeeLoading = ref(false)
    const outlet = ref(null)
    const outlets = ref([])
    const outletLoading = ref(false)
    const outletAssignments = ref([])
    const applyToValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const startDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const endDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const timeStartValue = ref(null)
    const timeEndValue = ref(null)
    const applyTo = ref('')
    const startDate = ref('')
    const endDate = ref('')
    const breakTime = ref(null)
    const remarks = ref('')
    const timeStart = ref('')
    const timeEnd = ref('')
    const modalApplyTo = ref(false)
    const modalStartDate = ref(false)
    const modalEndDate = ref(false)
    const modalTimeStart = ref(false)
    const modalTimeEnd = ref(false)
    const formState = ref(null)
    const formErrorFields = ref({
      outletErrorMessage: '',
      employeeErrorMessage: '',
      timeStartErrorMessage: '',
      timeEndErrorMessage: '',
      applyToErrorMessage: '',
      startDateErrorMessage: '',
      endDateErrorMessage: '',
      breakTimeErrorMessage: '',
      remarksErrorMessage: '',
    })

    const getEmployee = id => {
      get(`${process.env.VUE_APP_URI}/api/scheduler/employees/${id}/true`, employees, employeeLoading)
    }

    watch(() => props.data, async () => {
      outlet.value = props.data.outlet_id
      await getEmployee(outlet.value)

      employee.value = props.data.employee_id
      applyTo.value = props.data.apply_to
      startDate.value = props.data.start_from
      endDate.value = props.data.end_to
      timeStart.value = props.data.start_time.substr(0, 5)
      timeEnd.value = props.data.end_time.substr(0, 5)
      breakTime.value = props.data.breaktime
      remarks.value = props.data.remarks
      formState.value = 'Edit'
    })

    outletAssignments.value = root.$store.state.user.user.outlet_assignment.split(',')
    if (outletAssignments.value.length > 1) {
      get(`${process.env.VUE_APP_URI}/api/scheduler/outlets/${fnx.base64_encode(outletAssignments.value)}`, outlets, outletLoading)
    } else {
      outlet.value = root.$store.state.user.user.outlet_assignment
      get(`${process.env.VUE_APP_URI}/api/scheduler/employees/${outlet.value}/true`, employees, employeeLoading)
    }

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formState.value = null
      employee.value = null
      outlet.value = null
      applyTo.value = null
      startDate.value = null
      endDate.value = null
      timeStart.value = null
      timeEnd.value = null
      breakTime.value = null
      remarks.value = null
    }

    const submit = async () => {
      const formData = {
        employee: employee.value,
        outlet: outlet.value,
        applyTo: applyTo.value,
        startDate: startDate.value,
        endDate: endDate.value,
        timeStart: timeStart.value,
        timeEnd: timeEnd.value,
        breakTime: breakTime.value,
        remarks: remarks.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/scheduler/overtime-requests`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/scheduler/overtime-requests/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID')
        reset()
      }
    }

    return {
      getEmployee,
      employee,
      employees,
      employeeLoading,
      outlet,
      outlets,
      outletLoading,
      outletAssignments,
      modalTimeStart,
      timeStart,
      timeStartValue,
      modalTimeEnd,
      timeEnd,
      timeEndValue,
      modalApplyTo,
      applyTo,
      applyToValue,
      modalStartDate,
      startDate,
      startDateValue,
      modalEndDate,
      endDate,
      endDateValue,
      breakTime,
      remarks,
      icon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      openUploader,
    }
  },

  components: {
    snackbar,
  },
}
</script>
