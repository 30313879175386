<template>
  <v-card class="elevation-1">
    <v-card-title>
      Overtime Request
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          cols="12"
          lg="4"
          class="mb-0 pb-0"
        >
          <!-- From Date -->
          <v-dialog
            ref="dialog"
            v-model="modalFromDate"
            :return-value.sync="fromDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                outlined
                dense
                placeholder="From Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalFromDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalFromDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="mb-0 pb-0"
        >
          <!-- To Date -->
          <v-dialog
            ref="dialog"
            v-model="modalToDate"
            :return-value.sync="toDateValue"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                outlined
                dense
                placeholder="To Date"
                v-bind="attrs"
                :append-icon="icon.calendar"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              scrollable
            >
              <v-btn
                text
                color="primary"
                @click="modalToDate=false"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modalToDate=false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="mb-0 pb-0 text-right"
        >
          <v-btn
            color="primary"
            class="mr-2"
            @click="filter"
          >
            <v-icon
              small
            >
              {{ icon.filter }}
            </v-icon>
            Filter
          </v-btn>
          <v-btn
            color="primary"
            @click="resetFilter"
          >
            <v-icon
              small
            >
              {{ icon.reset }}
            </v-icon>
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.actions`]="{ item }">
        <div v-if="role==='encoder'">
          <v-icon
            v-if="item.processed_at === null"
            small
            class="mr-2"
            @click="editItem(item)"
          >
            {{ icon.edit }}
          </v-icon>
          <v-icon
            v-if="item.processed_at === null"
            small
            @click="deleteItem(item)"
          >
            {{ icon.delete }}
          </v-icon>
        </div>
        <v-icon
          v-else
          small
          @click="approveWasClicked(item)"
          title="Click to approve"
        >
          {{ icon.check }}
        </v-icon>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />

    <confirm-dialog
      :show="showConfirmDialog"
      @yes="approve"
      @no="showConfirmDialog=false"
    >
      <template v-slot:title>Confirm</template>
      <template v-slot:message>Are you sure you want to approve?</template>
    </confirm-dialog>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlusOutline,
  mdiFilter,
  mdiCancel,
  mdiCheckOutline,
} from '@mdi/js'
import getData from '@/composables/getData'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import confirmDialog from '@/components/ConfirmDialog.vue'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
    confirmDialog,
  },
  props: ['uniqueID'],
  setup(props, { emit, root }) {
    const showConfirmDialog = ref(false)
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const fromDate = ref('')
    const toDate = ref('')
    const modalFromDate = ref(false)
    const modalToDate = ref(false)
    const search = ref('')
    const headers = ref([])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const icon = {
      add: mdiPlusOutline,
      edit: mdiPencilOutline,
      delete: mdiDeleteOutline,
      filter: mdiFilter,
      reset: mdiCancel,
      check: mdiCheckOutline,
    }
    const filterValue = ref('')

    if (root.$store.state.user.user.role === 'encoder') {
      headers.value = [
        { text: 'Emp. No', align: 'start', value: 'employee_number' },
        { text: 'Emp. Name', value: 'employee_name' },
        { text: 'Apply To', value: 'apply_to_formatted' },
        { text: 'Start From', value: 'start_from_formatted' },
        { text: 'End To', value: 'end_to_formatted' },
        { text: 'Total Hours', value: 'total' },
        { text: 'Break Time', value: 'breaktime' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Created by', value: 'created_by' },
        { text: 'Approved at', value: 'approved_at' },
        { text: 'Approved By', value: 'approved_by' },
        { text: 'Processed', value: 'processed_at' },
        { text: 'Action', value: 'actions' },
      ]
    } else if (root.$store.state.user.user.role === 'approver') {
      headers.value = [
        { text: 'Outlet', align: 'start', value: 'outlet' },
        { text: 'Emp. No', value: 'employee_number' },
        { text: 'Emp. Name', value: 'employee_name' },
        { text: 'Apply To', value: 'apply_to_formatted' },
        { text: 'Start From', value: 'start_from_formatted' },
        { text: 'End To', value: 'end_to_formatted' },
        { text: 'Total Hours', value: 'total' },
        { text: 'Break Time', value: 'breaktime' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Created by', value: 'created_by' },
        { text: 'Action', value: 'actions' },
      ]
    }

    const {
      update,
      remove,
      responseMessageStatus,
      responseMessage,
    } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/scheduler/overtime-requests/masterlist`

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search, filterValue.value)
    })

    watch(searchTable, () => {
      getData(listUri, items, totalItems, loading, options, search, filterValue.value)
    })

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    const editItem = item => {
      emit('passData', item)
    }

    const deleteItem = async item => {
      await remove(`${process.env.VUE_APP_URI}/api/scheduler/overtime-requests/${item.id}`, loading)
      await getData(listUri, items, totalItems, loading, options, search)
    }

    const filter = async () => {
      if (fromDate.value !== '' && toDate.value !== '') {
        filterValue.value = fnx.base64_encode({ fromDate: fromDate.value, toDate: toDate.value })
        getData(listUri, items, totalItems, loading, options, search, filterValue.value)
      } else {
        responseMessage.value = 'Date(s) are required.'
        responseMessageStatus.value = 'error'
      }
    }

    const resetFilter = () => {
      filterValue.value = ''
      toDate.value = ''
      fromDate.value = ''
      getData(listUri, items, totalItems, loading, options, search)
    }

    let rowId = null
    const approveWasClicked = item => {
      showConfirmDialog.value = true
      rowId = item.id
    }

    const approve = async () => {
      await update(`${process.env.VUE_APP_URI}/api/scheduler/overtime-requests/${rowId}/approve`, loading)
      showConfirmDialog.value = false
      getData(listUri, items, totalItems, loading, options, search, filterValue.value)
    }

    return {
      search,
      headers,
      items,
      searchTable,
      icon,
      options,
      totalItems,
      loading,
      editItem,
      deleteItem,
      filter,
      resetFilter,
      approve,
      approveWasClicked,
      fromDateValue,
      fromDate,
      modalFromDate,
      toDateValue,
      toDate,
      modalToDate,
      role: root.$store.state.user.user.role,
      snackbar,
      responseMessageStatus,
      responseMessage,
      showConfirmDialog,
    }
  },
}
</script>
